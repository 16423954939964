import React, { useState, useEffect } from 'react';
import { Checkbox, Button, Typography } from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';
import { useDataModel, params, string, shape } from '@thd-nucleus/data-sources';
import { HOME_DEPOT_QUOTE_CENTER } from '../../constants';

export const ProductCompare = ({ onCompare, onSelectedForCompare, selectedForCompare, itemId }) => {
  const { additionalData } = useImpression({
    data: {
      id: itemId,
      component: 'ProductCompare',
      name: 'ProductCompare',
      position: 0
    }
  });

  const { parent } = additionalData || {};

  const MAX_COMPARABLE = 4;
  const MIN_COMPARABLE = 2;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(selectedForCompare.includes(itemId));
  }, [selectedForCompare, itemId]);

  const onChange = () => {
    setChecked(!checked);
    onSelectedForCompare(itemId, !checked);
    LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.compare', {
      podAction: 'product compare',
      podAnchorSku: itemId,
      parent
    });
  };

  const { data } = useDataModel('product', {
    variables: {
      itemId
    }
  });

  const { productSubType } = data?.product?.info || {};

  const isHDQCSku = productSubType?.name === HOME_DEPOT_QUOTE_CENTER;

  /* Product Compare has been deprecated and set to return null */
  return null;
};

ProductCompare.displayName = 'ProductCompareDeprecated';

ProductCompare.dataModel = {
  product: params({
    itemId: string().isRequired()
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      productSubType: shape({
        name: string()
      })
    })
  })
};

ProductCompare.propTypes = {
  onCompare: PropTypes.func,
  onSelectedForCompare: PropTypes.func,
  selectedForCompare: PropTypes.arrayOf(PropTypes.string),
  itemId: PropTypes.string.isRequired
};

ProductCompare.defaultProps = {
  onCompare: () => { },
  onSelectedForCompare: () => { },
  selectedForCompare: []
};
